/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable, when } from 'mobx'

import StatefulPromise from '@src/lib/StatefulPromise'
import Collection from '@src/service/collections/Collection'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type {
  CodableUserSettings,
  CodableUser,
  PhoneNumberModel,
  MemberModel,
  DirectNumberModel,
} from './model'
import { Invite, UserModel } from './model'
import type { AccountConnectionType } from './transport/account'

export default class UserStore {
  /**
   * Prefer using `UserStore.getCurrentUser()` instead if you expect the current
   * user to be ready.
   */
  current: UserModel | null = null
  currentUserPromise: Promise<UserModel>

  connections: AccountConnectionType[] | null = null
  connectionsError: Error | null = null
  connectionsPromiseStatus: 'idle' | 'pending' | 'done' = 'idle'
  connectionsPromise: Promise<AccountConnectionType[]> | null = null
  acceptInvitePromise: StatefulPromise<
    unknown,
    Parameters<UserStore['handleAcceptInvite']>
  >

  readonly invites = new Collection<Invite>({ bindElements: true })
  readonly acceptedInvites = new Collection<Invite>({ bindElements: true })

  constructor(private root: Service) {
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, 'UserStore', {
      current: root.storage.async((data: CodableUser) => new UserModel(root.user, data)),
      connections: root.storage.async(),
    })

    this.currentUserPromise = when(
      () => this.current !== null && !!this.current?.asMember,
    ).then(() => this.current as UserModel)

    this.subscribeToWebSocket()

    this.acceptInvitePromise = new StatefulPromise(this.handleAcceptInvite)
  }

  get phoneNumbers() {
    return this.root.phoneNumber.collection.list.filter(
      this.isCurrentUserMemberOfPhoneNumber.bind(this),
    )
  }

  /**
   * Returns a direct number that associated with the current organization.
   * Potentially, a user can be associated with multiple organizations which would result in multiple direct numbers.
   */
  get directNumber(): DirectNumberModel | null {
    return (
      this.current?.directNumbers.list.find(
        (number) => number.orgId === this.root.organization.current?.id,
      ) ?? null
    )
  }

  getMember(id: string): MemberModel | null {
    return this.root.member.collection.get(id)
  }

  get inviteForThisOrg(): Invite | null {
    const currentOrg = this.root.organization.current

    if (!currentOrg) {
      return null
    }

    const acceptedInvites = this.acceptedInvites.list

    const currentOrgInvite = acceptedInvites.find((iv) => iv.orgId === currentOrg.id)

    return currentOrgInvite || null
  }

  /**
   * Returns the current user if it's ready, otherwise throws an error.
   *
   * @returns The current user
   * @throws If the current user is not ready yet
   */
  getCurrentUser() {
    const currentUser = this.current
    if (!currentUser) {
      throw new Error(
        'UserStore.getCurrentUser() called before the current user was ready',
      )
    }

    return currentUser
  }

  fetch() {
    return this.root.transport.account.get().then(
      action((user) => {
        if (this.current) {
          this.current.deserialize(user)
        } else {
          this.current = new UserModel(this.root.user, user)
        }
      }),
    )
  }

  update(user: CodableUser) {
    return this.root.transport.account.update(user).then((user) => {
      if (this.current) {
        this.current.deserialize(user)
      } else {
        this.current = new UserModel(this.root.user, user)
      }
    })
  }

  updateUserSettings(settings: CodableUserSettings) {
    return this.root.transport.account.userSettings.update(settings)
  }

  fetchInvites = () => {
    return this.root.transport.account.invites
      .list({ includeAccepted: true })
      .then((invites) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        const all = invites.map((json) => new Invite().deserialize(json))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        const accepted = all.filter((invite) => !!invite.acceptedAt)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        const pending = all.filter((invite) => !invite.acceptedAt && !invite.cancelledAt)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        this.invites.putBulk(pending)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        this.acceptedInvites.putBulk(accepted)
      })
  }

  fetchInviteByToken(token: string) {
    return this.root.transport.account.invites.get(token)
  }

  fetchConnections = () => {
    // WEB-159 - Move the this.connectionsPromise and this.connectionsPromiseStatus code to an utility to fetch with suspense
    if (this.connectionsPromise) {
      return this.connectionsPromise
    }

    this.connectionsPromise = this.root.transport.account.connections
      .list()
      .then(
        action((result) => {
          this.connections = result.connections
          this.connectionsPromiseStatus = 'done'
          return result.connections
        }),
      )
      .catch(
        action((error) => {
          this.connectionsError =
            error instanceof Error ? error : new Error('Unknown error')
          this.connectionsPromiseStatus = 'done'
          throw error
        }),
      )
    this.connectionsPromiseStatus = 'pending'

    return this.connectionsPromise
  }

  acceptInvite(invite: Invite | string): Promise<unknown> {
    return this.acceptInvitePromise.run(invite)
  }

  private handleAcceptInvite(invite: Invite | string): Promise<unknown> {
    const token = typeof invite === 'string' ? invite : invite.token
    return this.root.transport.account.invites
      .accept(token)
      .then(() =>
        this.root.authV2.isEnabled
          ? this.root.authV2.getAccessToken
          : this.root.auth.refreshToken,
      )
      .then(() => this.root.reset())
  }

  rejectInvite(invite: Invite): Promise<any> {
    this.invites.delete(invite)
    return this.root.transport.account.invites.reject(invite.token)
  }

  setPassword = (password: string): Promise<any> => {
    return this.root.transport.auth.setPassword(password)
  }

  changePassword = (oldPassword: string, password: string): Promise<any> => {
    return this.root.transport.auth.changePassword(oldPassword, password)
  }

  private isCurrentUserMemberOfPhoneNumber(phoneNumber: PhoneNumberModel): boolean {
    const phoneNumberHasValidRole =
      phoneNumber.role === 'member' ||
      phoneNumber.role === 'admin' ||
      phoneNumber.role === 'owner'

    const userHasAccessToPhoneNumber = phoneNumber.users.some(
      (user) => user.id === this.current?.id,
    )

    return phoneNumberHasValidRole && userHasAccessToPhoneNumber
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      if (data.type === 'user-update') {
        if (this.current) {
          this.current.deserialize(data.user)
        } else {
          this.current = new UserModel(this.root.user, data.user)
        }
      }

      if (data.type === 'user-auth-update') {
        this.connections = data.connections
      }
    })
  }
}
